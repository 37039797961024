<template>
  <div ref="rootEl" class="hb-transition" :style="rootStyles">
    <transition :name="name" @before-leave="onBeforeLeave" @leave="onLeave">
      <slot></slot>
    </transition>
  </div>
</template>

<script lang="ts">
import { computed, ref } from 'vue'

export default {
  name: 'HbTransition',
  props: {
    name: { type: String, default: () => 'fade' },
    overflowed: { type: Boolean, default: () => true },
  },
  setup(props) {
    const rootEl = ref<HTMLElement>(null)
    const minWidth = ref<number>(null)
    const minHeight = ref<number>(null)
    const rootStyles = computed(() => ({
      minWidth: minWidth.value ? minWidth.value + 'px' : undefined,
      minHeight: minHeight.value ? minHeight.value + 'px' : undefined,
      overflow: props.overflowed ? 'hidden' : 'unset',
    }))
    const onBeforeLeave = () => {
      minWidth.value = rootEl.value.clientWidth
      minHeight.value = rootEl.value.clientHeight
    }
    const onLeave = () => {
      minWidth.value = null
      minHeight.value = null
    }

    return {
      rootEl,
      rootStyles,
      onBeforeLeave,
      onLeave,
    }
  },
}
</script>

<style scoped lang="scss">
.hb-transition {
  position: relative;
  transition: min-height 0.3s ease;

  > * {
    width: 100%;
    top: 0;
  }
}
</style>
